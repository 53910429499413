<template>
    <nav class="sf-menu-list">
        <template v-if="!!$slots.title">
            <slot name="title" />
        </template>
        <ul v-if="!!$slots.custom">
            <slot name="custom" />
        </ul>
        <ul v-if="!$slots.custom && routes && routes.length">
            <li
                v-for="(route, index) in routes"
                :key="`route_${index}`"
            >
                <NuxtLink
                    :to="getLink(route)"
                    :tabindex="indexTabs ? 0 : -1"
                >
                    {{ route.label }}
                </NuxtLink>
            </li>
        </ul>
    </nav>
</template>

<script setup>
defineProps({
    routes: {
        type: Array,
        default: null
    },
    indexTabs: {
        type: Boolean,
        default: true
    }
});

const getLink = (route) => route.path ?? route.to ?? '';
</script>

<style src="./menu-list.less" lang="less"></style>
